var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      {
        attrs: {
          "custom-class": "company-info-dialog",
          visible: _vm.visible,
          top: "10vh",
          width: "400px",
        },
        on: {
          "update:visible": function ($event) {
            _vm.visible = $event
          },
          close: _vm.handleClose,
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "title" },
        [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("el-divider", { staticStyle: { margin: "6px 0", height: "2px" } }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "text-align": "center" },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Only .xlsx file is acceptable." } },
                [
                  _c(
                    "el-upload",
                    {
                      key: _vm.uploadKey,
                      attrs: {
                        action: _vm.fileUploadUrl,
                        "http-request": _vm.handleHttpRequest,
                        "show-file-list": false,
                        limit: 1,
                        headers: _vm.headers,
                        accept: _vm.accept,
                      },
                    },
                    [
                      _c("el-button", { attrs: { size: "mini" } }, [
                        _vm._v("Upload File"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }